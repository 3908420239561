import { useState,useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png";
import carouselImage1 from "../assets/img/Burj Khalifa.jpg";
import carouselImage2 from "../assets/img/Museum of Future.jpg";
import carouselImage3 from "../assets/img/Marina cruise.jpg";
import carouselImage4 from "../assets/img/Miracle garden.jpg";
import carouselImage5 from "../assets/img/Desert Safari - Home.jpg";
import { Link } from "react-router-dom";
import {SmallCardData} from '../components/Cards/SmallCardData';

export const ActivityComp = () => {
  const [price, setPrice] = useState([]);

  useEffect(() => {
  const filteredPrice = SmallCardData.filter(price => price.price);
  const cost= filteredPrice.map(price => price.price)
  setPrice(cost);
  }, [])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  function handleScroll(){
    window.scrollTo(0, 0);
};

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Things to do in Dubai</h2>
                        <p>Find the best attractions & experience of UAE.<br></br>Contact us for more discounted rates.</p>
                        <a className='activities-button' href='/activities'>Check all UAE activities here</a>
                      <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <Link className='link-click' to='/activities' onClick={handleScroll}>
                            <div className="item">
                            <img src={carouselImage1} alt="" />
                            <h5 className='activity-header'>At the Top - Burj Khalifa</h5>
                            <p className='activities-details'>Ticket for Burj Khalifa At the Top(level 124-125th)
                            <br></br><br></br>
                            From{price[0]} AED</p> 
                        </div>
                        </Link>
                        <Link className='link-click' to='/activities' onClick={handleScroll}>
                        <div className="item">
                        <img src={carouselImage2} alt="" />
                        <h5 className='activity-header'>Museum Of The Future</h5>
                        <p className='activities-details'>Tickets for the astonishing Museum of Future
                        <br></br><br></br>
                        From{price[13]} AED</p> 
                        </div>
                        </Link>
                        <Link className='link-click' to='/activities' onClick={handleScroll}>
                        <div className="item">
                        <img src={carouselImage3} alt="" />
                        <h5 className='activity-header'>Marina Cruise</h5>
                        <p className='activities-details'>Admission to Marina Cruise with unlimited BBQ dinner
                        <br></br><br></br>
                        From{price[1]} AED</p> 
                       </div>
                       </Link>
                       <Link className='link-click' to='/activities' onClick={handleScroll}>
                       <div className="item">
                       <img src={carouselImage4} alt="" />
                       <h5 className='activity-header'>Miracle Garden</h5>
                       <p className='activities-details'>Tickets for the world's biggest astonishing floral garden
                       <br></br><br></br>
                       From{price[9]} AED</p>
                      </div>
                      </Link>
                      <Link className='link-click' to='/activities' onClick={handleScroll}>
                      <div className="item">
                      <img src={carouselImage5} alt="" />
                      <h5 className='activity-header'>Desert Safari with BBQ</h5>
                      <p className='activities-details'>Enjoy dune bashing & BBQ buffet with much more
                      <br></br><br></br>
                      From{price[2]} AED</p>
                     </div>
                     </Link>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="" />
    </section>
  )
}
