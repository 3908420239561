import { Col} from "react-bootstrap";

export const Contact = () => {

  return (
      <Col lg={12}>
        <div className="newsletter-bx">
        <a className="contact-anchor" href='tel:+971567107636'><i className="fa-sharp fa-solid fa-phone fa-lg ficon-color"></i> Let's Talk +971567107636</a>
        <a className="contact-anchor" href = "mailto: support@tripaway.ae"><i className="fa-solid fa-envelope fa-lg ficon-color"></i> Shoot us Emails</a>
        <a className="contact-anchor" href="https://api.whatsapp.com/send?phone=971567107636&text=Hi!+I+need+a+Trip+Away!+😀"><i className="fa-brands fa-whatsapp fa-lg ficon-color"></i> Let's Chat on Whatsapp</a>
        </div>
      </Col>
  )
}
