

export const SmallCardData = [
    {
        title : "Burj Khalifa Tour",
        image : require('../../assets/img/Burj Khalifa.jpg'),
        carouselImage1: require('../../assets/img/Burj Khalifa 1.jpg'),
        carouselImage2: require('../../assets/img/Burj Khalifa 2.jpg'),
        carouselImage3: require('../../assets/img/Burj Khalifa 3.jpg'),
        text : "Entry ticket and access to the top Levels(124-125th) of Burj Khalifa - At the Top.",
        price: " 165",
        about: `Dubai's Burj Khalifa, the tallest building in the world, stands tall and proud amongst all. 
        At 2,716 feet (830 meters) tall, this living wonder is the most popular attraction in Dubai.
        One of the major landmarks of the city, the interiors of the Burj is as majestic as the steely exterior.
        Take a look at the marvelous building from the inside by joining the Dubai Burj Khalifa Tour with us. 
        During the tour, you are shown a multimedia presentation about the history of Dubai as well as the story of the construction of the building.
        Once you have gathered information about this tall structure, you will be taken to the 124-125th observatory deck (At the Top) on the fastest elevators.
        Here you get a chance to have a 360-degree panoramic view of the entire city of Dubai.`,
        inclusions: `● Entry ticket and access to the top Levels(124-125th) of Burj Khalifa.
        ● 360 degree Sky View Observatory tickets.
        ● world’s fastest double-deck elevators, cruising at 10 m/s.
        ● world’s highest observation deck with an outdoor terrace at 555 meters.
        ● Take a closer look at the world through high-powered telescopes.
        ● Transfers (on additional charges)
        ● Duration 2 to 3 hours `,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Cancel 24 hours prior from the date of activity for a partial refund.
        ● This Ticket is for non prime hours. Additional charges for Prime hours tickets.
        ● Bring your Emirates ID or Passport as ID proof.
        ● Activity tickets are subject to availability.`
    },
    {
        title : "Dubai Marina Cruise",
        image : require('../../assets/img/Marina cruise.jpg'),
        carouselImage1: require('../../assets/img/Marina cruise 1.jpg'),
        carouselImage2: require('../../assets/img/Marina cruise 2.jpg'),
        carouselImage3: require('../../assets/img/Marina cruise 3.jpg'),
        text : "Unlimited buffet dinner on the Dubai Marina Cruise",
        price: " 95",
        about: `Experience inexplicable and beyond your imagination with our 90-min classy cruise. 
        The canal will leave you and your loved ones impressed. 
        This traditional dhow will offer you the best views of modern Dubai from the most unexpected angles. 
        Witness the glorious architectures of Dubai that the world acknowledges while you feast on the international cuisines.
        Entertainment will be arranged onboard and you will be cruising over the Dubai’s Marina while enjoying the savory dishes and a complimentary drink.
        The stunning view, meals and the live entertainment will give you a dazzling experience.`,
        inclusions: `● 90 minutes of cruising in Dubai Marina.
        ● Welcome drinks, Water, Tea & Coffee.
        ● Unlimited Buffet Dinner (Veg and Non-veg options are available).
        ● Live entertainment - Tanoura Show with Soft background music.
        ● Dubai Marina's spectacular view.
        ● Hotel Pick up (If transfer option selected)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● For transfers additional AED 50 charges.
        ● During the Month of Ramadan / Dry Days No Live Entertainment & Alcohol Drinks will be served as per the Government guidelines.
        ● Cancel 24 hours prior from the date of activity for a partial refund.`
    },
    {
        title : "Desert Safari",
        image : require('../../assets/img/Desert Safari - Home.jpg'),
        carouselImage1: require('../../assets/img/Desert safari 1.jpg'),
        carouselImage2: require('../../assets/img/Desert safari 2.jpg'),
        carouselImage3: require('../../assets/img/Desert safari 3.jpg'),
        text : "BBQ, belly dance, camel ride & much more on the Dubai desert safari",
        price: " 95",
        about: `The desert safari trip includes spending a few hours time in a Bedouin-style tent,right in the middle of the desert. 
        The tour begins with our vehicle picking you up from your home or hotel and driving you down to the outskirts of the desert. 
        Your exciting desert journey in a 4 X 4 Land Cruiser that would take you on a dune bashing ride.
        Arrive at the desert campsite & you can enjoy your time by riding a camel, smoking a flavored shisha, 
        drinking the traditional Arabic coffee, enjoying the captivating belly dance and Tanura dance.
        Apart from this, you can try out the traditional costumes, get a henna tattoo and enjoy a delicious buffet dinner.`,
        inclusions: `● 4*4 Pick & Drop from Home/Hotel.
        ● Unlimited refreshments (tea, coffee, water, soft drinks).
        ● Sunset photography opportunity.
        ● Dune bashing in fully air-conditioned 4x4 Vehicle.
        ● Live entertainment - Tanoura & belly dance show with Soft background music.
        ● BBQ dinner with vegetarian and non-vegetarian dishes.
        ● Fire show.
        ● Shisha smoking facility at the shisha corner.
        ● Camel riding.
        ● Duration 6 to 7 hours`,
        thingsToRemember: `● Pickup Time- 2.00 p.m. – 2.30 p.m.
        ● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● During the Month of Ramadan / Dry Days No Live Entertainment & Alcohol Drinks will be served as per the Government guidelines.
        ● Cancel 24 hours prior from the date of activity for a partial refund.
        ● Dune Bashing is not recommended for pregnant ladies, senior citizens or suffering with heart problems.`
    },
    {
        title : "Dubai Frame",
        image : require('../../assets/img/Dubai frame.jpg'),
        carouselImage1: require('../../assets/img/Dubai package C1.jpg'),
        carouselImage2: require('../../assets/img/Dubai frame 2.jpg'),
        carouselImage3: require('../../assets/img/Dubai frame 3.jpg'),
        text : "Get access to world's biggest frame with the history & future of Dubai together",
        price: " 63",
        about: ` Dubai Frame is of two vertical towers of 150 meters in height connected by a 100-square-meter bridge is a new highlight of the Dubai skyline. 
        The Dubai Frame has a museum and gallery where you can see the growth of Dubai from a simple fishing village to the ultra-modern city it 
        is today with the use of photos and advanced technology.
        On one side is the charming old Dubai and on the other side is the modern new Dubai which reflects the past and future of Dubai. `,
        inclusions: `● Access to see the world’s biggest Picture Frame.
        ● See the past & future of Dubai.
        ● History museum of Dubai.
        ● Enjoy a walk across the glass bridge on the Sky Deck.
        ● Dubai future gallery.
        ● Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed.`
    },
    {
        title : "Dubai Aquarium",
        image : require('../../assets/img/Dubai Aquarium.jpeg'),
        carouselImage1: require('../../assets/img/Dubai Aquarium 1.jpeg'),
        carouselImage2: require('../../assets/img/Dubai Aquarium 2.jpg'),
        carouselImage3: require('../../assets/img/Dubai Aquarium 3.jpeg'),
        text : "Get access to world's largest aquarium inside Dubai Mall",
        price: " 135",
        about: `The center attraction of Dubai Mall, Dubai Aquarium is home to more than 30,000 aquatic animals 
        including the star of the aquarium - 40-year-old giant crocodile weighing 750 kg along with pelagic fish, piranha, 
        penguin, lizards, snakes, rays, and sharks. It is amazing to see the aquatic life in the 10 million liter water tank 
        which is also the largest suspended aquarium in the world. You also get access to the underwater zoo.
        Walk through tunnel and snorkeling cage as additional experiences.`,
        inclusions: `● Access to see the world’s largest aquarium.
        ● Access to underwater zoo.
        ● Admire over 33K+ fascinating underwater creatures in the Aquarium. 
        ● Get a captivating 270-degree view over the aquarium and its inhabitants.
        ● Walk through the 48-meter-long underwater tunnel.
        ● Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● The Dubai Aquarium and Underwater Zoo tickets are non-refundable.`
    },
    {
        title : "Dubai Dolphinarium",
        image : require('../../assets/img/Dubai dolphinarium.jpg'),
        carouselImage1: require('../../assets/img/Dubai dolphinarium 2.jpg'),
        carouselImage2: require('../../assets/img/Dubai dolphinarium 3.jpg'),
        carouselImage3: require('../../assets/img/Dubai dolphinarium 1.jpg'),
        text : "Get access to 45 minutes Dolphins & seal show",
        price: " 85",
        about: `Dolphins are said to be the most intelligent mammals, and you can have a rendezvous with them at the Dubai Dolphinarium with us.
         Comprised of state-of-the-art light and sound technology, this dolphinarium is set up in a 5000 square meters area. 
         The 45 minutes long dolphin and seal shows are held in an 180-degree amphi theatre. 
         The impressive performances of the dolphins and seals would be equally enjoyed by kids and adults. 
         You also get a chance to interact with these Dolphins directly.`,
        inclusions: `● Access to see the world’s best 45 minutes Dolphin & Seal show.
        ● Encounter with some exotic birds.
        ● Get up close with over 20 unique and rare bird species such as Amazon parrots, Electus parrots, macaws (green-winged), falcons, hornbills, and toucans. 
        ● Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Dubai Dolphinarium tickets are non-refundable.
        ● Please carry a valid photo ID with you.
        ● Tickets valid for 30 days from the date of purchase.
        ● 5 AED Creek Park entry fee will be charged additionally upon your visit.
        ● Show timings: Wednesday - Monday (11 a.m , 2 p.m & 6 p.m). Tuesday is closed.`
    },
    {
        title : "Dubai City Tour",
        image : require('../../assets/img/Dubai package 1.jpg'),
        carouselImage1: require('../../assets/img/Dubai package C4.jpg'),
        carouselImage2: require('../../assets/img/Dubai package C7.jpg'),
        carouselImage3: require('../../assets/img/Dubai package C10.jpg'),
        text : "4 hours Dubai city tour including Gold souk, museum, jumeirah beach, marina etc",
        price: " 65",
        about: `4 hour Dubai city tour would take you to the most prominent places in Dubai.
        You also get to see two different sides of Dubai, the historical one and the modern one. 
        Beginning with the Dubai Museum, located in the Al Fahidi Fort, 
        you’ll get to experience Dubai before it discovered oil and became the power city it is today. 
        This is followed by a drive along the Dubai Creek, your Dubai city tour would not be complete if you do not see its modern side. 
        So now get ready to see some of the emirate’s futuristic skyscrapers and awe-inspiring architectures! We’ll drive you past Sheik Zayed Road, 
        allowing you to catch a glimpse of Dubai’s modern icons like Mall of the Emirates, Burj Khalifa, Dubai Mall and Burj Al Arab along the way.
        With the trip extending to Jumeirah region, more and more attractions will be revealed. 
        Take in the spellbinding traditional Arabic architecture as you make your way through the charming Jumeirah Mosque. 
        You’ll also pass through the one-of-its-kind Palm Islands, Jumeirah Beach, and enjoy photo stops at Atlantis Palm Hotel.
        This tour package by us has been designed to cover the most important places in the city of Dubai. 
        This tour package is perfect for those who want to view the entire city of Dubai in a short period of time.`,
        inclusions: `● Dubai Museum (Drive through)
        ● Al Seef Area (Drive through)
        ● Visit Spice Market (Short Stop)
        ● Visit Gold Souk (Short Stop)
        ● Jumeirah Mosque - (Drive Through)
        ● Miraj Museum (Short Stop)
        ● Jumeirah Beach (Short Stop)
        ● Atlantis Hotel (Photo stop)
        ● Mall of Emirates - (Drive Through)
        ● Dubai Mall - (Drive Through)
        ● Private Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancel 24 hours prior from the date of activity for a partial refund.`
    },
    {
        title : "Abu Dhabi City Tour",
        image : require('../../assets/img/Abu Dhabi tour.jpg'),
        carouselImage1: require('../../assets/img/Abu dhabi tour 1.jpg'),
        carouselImage2: require('../../assets/img/Ferrari world 1.jpg'),
        carouselImage3: require('../../assets/img/Abu dhabi tour 2.jpg'),
        text : "9 hours Abu Dhabi city tour including Grand Mosque, Ferrari world, Emirates palace etc",
        price: " 100",
        about: `Abu Dhabi city tour would take you to the best tourist places in the Emirate city. 
        Our local tour guide will help you best explore the historical and architectural brilliance of the city's attractions.
        Our tour begins with a visit to Sheikh Zayed Mosque. 
        This is followed by places like Abu Dhabi Corniche, Emirates Palace, Abu Dhabi Culture and Heritage Centre etc. 
        Visit the famous WTC Mall and indulge in irresistible luxuries. 
        This tour also takes you to the famous Yas Island along with the world's biggest theme park the Ferrari World.`,
        inclusions: `● Ferrari World (Stopover)
        ● Date market (Stopover)
        ● Drive through Corniche with a Photo stop
        ● Emirates Palace Hotel (Photo stop)
        ● Visit to the Art Gallery 
        ● Sheikh Zayed Mosque
        ● Private Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancel 48 hours prior from the date of activity for a partial refund.
        ● Tour begins in Dubai from your hotel and starts at 09:00am.
        ● The Sheikh Zayed mosque is open between 8.30am and 11:30am, Sunday to Thursday.
        ● Ladies visiting the Mosque are required to cover their head with a scarf. Shorts, beachwear or sleeveless outfits are prohibited. 
        ● Men visiting the Mosque should wear long trousers.
        ● Please bring valid ID proof and ticket voucher.`
    }, 
    {
        title : "IMG Worlds of Adventure",
        image : require('../../assets/img/IMG.png'),
        carouselImage1: require('../../assets/img/IMG 1.jpg'),
        carouselImage2: require('../../assets/img/IMG 2.jpg'),
        carouselImage3: require('../../assets/img/IMG 3.jpg'),
        text : "Get access to worlds biggest indoor theme park over 1.5 million square feet",
        price: " 175",
        about: `IMG is the world’s biggest indoor theme park, featuring over 1.5 million square feet of thrilling attractions and activities.
        With four diverse zones - all packed with an extensive array of rides, shopping outlets, and dining options.
        A segment of Dubailand and nestled in Dubai’s City of Arabia, it can be impeccably accessed through IMG Boulevard which also forms one of the park’s main zones,
        with attractions by way of The Haunted Hotel and Adventure Photography. 
        It’s the first of its kind theme park to have exclusive zones for several immersive rides based on popular comic animation brands:
        Cartoon Network and Marvel Entertainment.
        IMG tickets cover access to Lost Valley, where guests can drop in for an adrenaline-pumping session of dinosaur adventure.`,
        inclusions: `● Entry to the world’s largest indoor theme park.
        ● Enter four distinct lands of action-packed amusement and breathtaking thrill.
        ● Surround yourself with your favorite superhero-inspired rides and games at the Marvel zone.
        ● Step inside the Lost Valley zone for a hair-rising yet fun-filled journey with dinosaurs.
        ● IMG Boulevard invites you to explore its terrific sections like Haunted Hotel.
        ● Several dining and shopping choices available in every zone.
        ● Get ready for an incredible adventure and fun with Cartoon Network’s exciting rides.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Miracle Garden Dubai",
        image : require('../../assets/img/Miracle garden.jpg'),
        carouselImage1: require('../../assets/img/Miracle garden 1.jpg'),
        carouselImage2: require('../../assets/img/Miracle garden 2.jpg'),
        carouselImage3: require('../../assets/img/Miracle garden 3.jpg'),
        text : "Get access to world's biggest and largest flower garden",
        price: " 75",
        about: `Travel to the botanical paradise of Dubai Miracle Garden, spanning 72000 square meters of the stunningly laid out landscape.
        It has become one of Dubai’s most visited attractions, drawing millions of visitors per year.
        As you enter the garden, stroll along the painstakingly manicured themed gardens as well as pathways, 
        and admire its several unique structures and water features decked up with a vivid array of flowers and plants. 
        Filled with over 100 million blooming flowers from 70 plus rare, exotic and indigenous floral species, 
        The garden is also recognized by Guinness Book of World Records for its astonishing flower wall, which is the largest of its kind in the world.`,
        inclusions: `● Entry to the biggest natural flower garden in world.
        ● Be surrounded by over 45 million flowers.
        ● Walk under the canopy of vibrant umbrellas and relax at the garden’s many shaded cool areas.
        ● Butterfly garden.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed.
        ● Timings are from 9:00 am to 9:00 pm(Sunday - Thursday) 9:00 am - 11:00 pm(Fridays) and 9:00 am - 10:00 pm(Saturdays)
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Global Village",
        image : require('../../assets/img/Global village.jpg'),
        carouselImage1: require('../../assets/img/Global village 1.jpg'),
        carouselImage2: require('../../assets/img/Global village 2.jpg'),
        carouselImage3: require('../../assets/img/Global village 3.jpg'),
        text : "Get to see 75+ countries with different cultures",
        price: " 22",
        about: `It is known as the world's largest tourism, leisure and entertainment project located in Dubai. 
        We bring you the best of Global Village - the region’s cultural, entertainment and tourism project with unlimited fun, excitement and adventure.
        Visited by over five million people every year, The Global Village brings together the best of cultural offerings through 32 giant stalls 
        and pavilions representing more than 75 countries with different cultures. 
        The food, the souvenirs, the shopping, the rides, and the excitement all make it a cosmopolitan and diverse experience for all our guests.
        Global Village was started as an initiative to highlight the cultural aspect of Dubai and the cosmopolitan blend that the city.`,
        inclusions: `● Entry to the ultimate shopping and entertainment attraction.
        ● The open-air multi-cultural entertainment complex has close to 30 pavilions, representing about 80 countries from across the world.
        ● Discover authentic culture, heritage and culinary flavors of not only UAE but also other international destinations.
        ● With your ticket, you’ll get to watch several cultural shows and concerts by acclaimed artists.
        ● This gives you the unique chance to explore the world in a day.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed.
        ● Global Village Dubai is open from Sat - Wed(4:00pm – 12:00am) and Thu – Fri(4:00pm – 1:00am).
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "The View At The Palm",
        image : require('../../assets/img/View at Palm.jpg'),
        carouselImage1: require('../../assets/img/View at palm 1.jpg'),
        carouselImage2: require('../../assets/img/View at palm 2.jpg'),
        carouselImage3: require('../../assets/img/View at palm 1.jpg'),
        text : "Chance to see the biggest manmade island from the top, The Palm Island",
        price: " 95",
        about: `The Palm Jumeirah known to be the biggest manmade island in the world, shaped in the form of a palm tree. 
        Each of the 16 fronds of the palm island is home to luxurious hotels, beautiful villas, high-end boutiques and malls, beaches etc.
        Moreover, there is the Hotel Atlantis the Palm, which is the crowning glory of the island. 
        With the opening of The View, viewing the aerial view of the Palm Jumeirah has become one of the most popular attractions of the city.
        The View is the observation deck on the 52nd floor of the Palm Tower. 
        Take the elevator to the observation deck that in 240 meters above the palm island. 
        Once you reach the deck, you get to see a 360 degree view of the Palm Jumeirah, the Dubai skyline and the Arabian Gulf.`,
        inclusions: `● Admission to The View at The Palm Jumeirah Dubai at Palm Tower.
        ● Access to Level 52.
        ● Grand 360 degree display of the iconic Palm Jumeirah, Arabian Gulf and beyond.
        ● Striking elevator ride complete with floor-to-ceiling digital experience.
        ● Change to see the whole Palm island from the top.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● This ticket is for Non Prime hours. Additional charges for Prime hours ticket.
        ● Cancellation not allowed.
        ● The View at The Palm timings are from Prime hours: 4:00 pm to 6:30 pm & Non-prime hours: 9:00 am to 3:30 pm & from 7:00 pm to closing time.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Address Sky View",
        image : require('../../assets/img/Sky view.jpg'),
        carouselImage1: require('../../assets/img/Sky view 1.jpg'),
        carouselImage2: require('../../assets/img/Sky view 2.jpg'),
        carouselImage3: require('../../assets/img/Sky view 3.jpg'),
        text : "See the Dubai Skyline from the top with 360 degree panoramic view",
        price: " 79",
        about: `Located on floors 52 and 53 of the twin Address Sky View Hotel, the Observatory offers an unforgettable experience.  
        Take a thrilling ride in the panoramic glass elevator to more than 219.5 metres above the ground. 
        Walk the 25-metre glass floor, and enjoy spectacular views of the city’s fabulous skyline.
        Sky Views Edge Walk is the most thrilling attraction in Dubai, and the first of its kind in region. 
        It is the city’s highest full circle hands-free walk on a ledge which encircles the top of the Tower’s main pod.`,
        inclusions: `● Admission to The Address Sky View.
        ● Complimentary glass slide from 53rd to 52nd floor.
        ● Grand 360 degree display of the iconic Dubai Skyline.
        ● Access to 52nd & 53rd floors.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● This ticket is for Non Prime hours. Additional charges for Prime hours ticket.
        ● Cancel 24 hours prior from the date of activity for a partial refund.
        ● The timings to experience Sky View Dubai are Off-peak hours: 10:30 am to 3 pm and 7 pm to 9 pm. Peak hours: 4 pm to 6:30 pm.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Museum Of Future",
        image : require('../../assets/img/Museum of Future.jpg'),
        carouselImage1: require('../../assets/img/Museum of future 1.jpg'),
        carouselImage2: require('../../assets/img/Museum of future 2.jpeg'),
        carouselImage3: require('../../assets/img/Museum of future 3.jpg'),
        text : "See the world's most beautiful building with no corners",
        price: " 145",
        about: `The Museum of future, a recent addition, would surely leave you spellbound. 
        Because of it's unique shape, Museum of future is also holding the record of world's most beautiful building with no corner edge.
        The Museum of the future is as unique as it sounds and offers the visitors extraordinary sensory and visual experiences while being educational.
        The best part about this place is that unlike other museums, you do not have to be cautious about what you touch. 
        On the other hand, this is an interactive museum and the more you touch and engage with the exhibits.`,
        inclusions: `● Entry tickets to Museum of the future.
        ● Chance to see the world's most beautiful building from inside.
        ● Futuristic museum with space atmosphere.
        ● Access to open deck.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed. Activity date or time cannot be altered or rescheduled once booked.
        ● The timings of Museum of Future is 10 AM – 7:30 PM(All days).
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Dubai Parks & Resorts",
        image : require('../../assets/img/Dubai parks.jpg'),
        carouselImage1: require('../../assets/img/Dubai parks 1.jpg'),
        carouselImage2: require('../../assets/img/Dubai parks 2.jpg'),
        carouselImage3: require('../../assets/img/Dubai parks 3.jpeg'),
        text : "Entry tickets for BOLLYWOOD PARKS, LEGOLAND, MOTIONGATE and much more.",
        price: " 235",
        about: `Select our Single Day Passes or Multi-Day Pass with each of them offers a variety of flexible options. 
        Credited to be the first Integrated Resort in the region, Dubai Parks and Resorts™ has three theme parks, one water park, 
        a Polynesian-themed family resort, and a dedicated retail and dining section, in its colossal 16 million square feet site. 
        Riverland, with a host of leisure, dining and retail choices, serves as the gateway to this resort. 
        MOTIONGATE™ Dubai is one of the three theme parks that make up the key attractions of this resort. 
        Studio Central, Smurfs Village, Dream Works, Sony Pictures, and Lions gate are its main sections.
        Bollywood movie lovers and those who are fascinated by Indian culture and traditions will enjoy exploring the resort’s theme park,namely, BOLLYWOOD PARKS™.
        Bollywood classics/characters, enriched with an incredible dose of adventure, fun, romance and music. 
        Another theme park is LEGOLAND® Dubai which offers 40 plus LEGO® themed rides and shows exclusively.
        There are six themed sections including Factory, LEGO® City, Kingdoms, Adventure, Imagination, and Miniland.`,
        inclusions: `● Entry tickets to region’s largest and one-of-its-kind leisure attraction.
        ● Enjoy access to dining and leisure attractions at Riverland™ Dubai 
        ● At MOTIONGATE™ Dubai, take to all rides, games and shows inspired by Hollywood movies.
        ● BOLLYWOOD PARKS™ is sure to blow you away with exciting rides and shows themed around iconic Hindi cinemas.
        ● LEGOLAND® Dubai has six zones such as FACTORY, IMAGINATION, LEGO® CITY, ADVENTURE, KINGDOMS and MINILAND.  
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed. Tickets valid for 3 months.
        ● The Dubai Parks & Resorts timings are on Saturday - Thursday from 10:00 am - 10:00 pm and 10:00 pm - 11:00 pm on Fridays.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Ferrari World",
        image : require('../../assets/img/Ferrari world.jpg'),
        carouselImage1: require('../../assets/img/Ferrari world 1.jpg'),
        carouselImage2: require('../../assets/img/Ferrari world 2.jpg'),
        carouselImage3: require('../../assets/img/Ferrari world 3.jpg'),
        text : "Entry tickets to world of racing, sport cars & thrilling rides.",
        price: " 270",
        about: `The Ferrari World is spread over an area of 86,000 square meters and has been built using 12,000 tons of steel has numerous thrilling rides
        like Formula Rossa, The Racing Legends, Bell'Italia, Fiorana GT Challenge, Junior GT, Speed of Magic, Scuderia Challenge, Paddock, etc. 
        Amongst these rides, the Formula Rossa is the most popular one. Running at the speed of 240 km/hour, it is the fastest of the lot.
        Warner Bros branded indoor theme park assures a fun-filled family day for all.
        The world's leading waterpark located in the capital of UAE brings to you an unforgettable experience. 
        With more than 40 exhilarating rides, slides, and attractions. The park assures a wonderful experience for all age groups.`,
        inclusions: `● General Admission ticket to the venue.
        ● Hit several amazing rides, games and other attractions all themed around Ferrari sports car brands.
        ● Feel the rush as you take to the fastest of its kind Formula Rossa, Flying Aces, Fiorano GT Challenge, and Scuderia Challenge.
        ● Get ready for celluloid adventure like no other, thanks to Ferrari-inspired shows including Cinema Maranello.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Dubai Safari Park",
        image : require('../../assets/img/Safari park.jpg'),
        carouselImage1: require('../../assets/img/Dubai safari 1.jpg'),
        carouselImage2: require('../../assets/img/Dubai safari 2.jpg'),
        carouselImage3: require('../../assets/img/Dubai safari 3.jpeg'),
        text : "Entry tickets to countries biggest safari park.",
        price: " 55",
        about: `Time to pay a visit to the most awaited and newly unveiled Dubai Safari Park. 
        The anticipated animal sanctuary is a large campus that has cool and breezy temperature, green places and eco-friendly facilities.
        Built over 400 hectares, the wildlife park makes people flock to its destination because it caves unbelievable 2500 animals including 250 species.
        Occupying 5 sections in a row, the Safari Park mentions them as Safari Village-a land of Cheetah, giraffe, bird theatre and air-conditioned walkway. `,
        inclusions: `● Meet animals from several unique species, maintained in their natural habitat.
        ● Indulge in some exciting activities like elephant ride, camel ride and feeding giraffes.
        ● Enjoy the most thrilling activity with a drive-through experience aboard a powerful vehicle.
        ● See 100 plus varieties of lush plants, apart from misting machines, chilled pools across the park.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed.
        ● Dubai Desert safari timings are 9am - 5pm daily.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Kayaking In Dubai",
        image : require('../../assets/img/Kayak dubai.jpg'),
        carouselImage1: require('../../assets/img/Kayak dubai 1.jpg'),
        carouselImage2: require('../../assets/img/Kayak dubai 2.jpg'),
        carouselImage3: require('../../assets/img/Kayak dubai 3.jpg'),
        text : "One hour Kayaking in Dubai.",
        price: " 80",
        about: `Kayak trip makes a great choice for those who wish to enjoy Dubai’s sights with water sports.
        Anyone who has completed 16 years can participate in this amazing water activity. 
        Moreover, the paddler’s body weight should be minimum 45 kg and not exceed 100 kg.  
        On this one-hour water adventure, guests can kayak down Dubai’s calm water and scenic views along the way. 
        We offer both single and double kayak tours.`,
        inclusions: `● Enjoy a one-hour single Kayak / double Kayak ride.
        ● Safety gears.
        ● Marina view.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed.
        ● Timings daily, 9 am - 6:30 pm.
        ● Appropriate clothing/swimwear must be worn.
        ● Children aged 7 and above are allowed to do this activity.  
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Skydive Dubai",
        image : require('../../assets/img/Skydive dubai.jpg'),
        carouselImage1: require('../../assets/img/Skydive 1.jpg'),
        carouselImage2: require('../../assets/img/Skydive 2.jpg'),
        carouselImage3: require('../../assets/img/Skydive 3.jpg'),
        text : "Enjoy skydiving from a whopping height of 13,000 feet over the Palm",
        price: " 2099",
        about: `A skydive is the quickest and easiest way to experience the thrill of freefall at over 120 miles per hour, 
        while securely harnessed to one of our experienced and accomplished instructors. 
        Adrenaline enthusiasts can live this adventure at our world-class location the Desert Dropzone or the Palm Dropzone`,
        inclusions: `● Enjoy Tandem skydiving from a whopping height of 13,000 feet.
        ● Free-fall for 60 seconds at 120 miles / hour 
        ● Accompanied by an expertly trained instructor
        ● Pictures and videos of Sky Dive experience 
        ● Top-notch safety measures provided with safety gears.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed.
        ● Kids below 12 years are not allowed.
        ● Pregnant women are not allowed to take this trip.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Hot Air Balloon",
        image : require('../../assets/img/Hot air ballon.jpg'),
        carouselImage1: require('../../assets/img/Hot air ballon 1.jpg'),
        carouselImage2: require('../../assets/img/Hot air ballon 2.jpg'),
        carouselImage3: require('../../assets/img/Hot air ballon 3.jpg'),
        text : "Enjoy UAE desert & sunrise from a height of 4,000 feet",
        price: " 799",
        about: `The journey to 4000 feet in the air is peaceful and exhilarating at the same time. 
        After enjoying your adventure balloon ride the balloons land somewhere different in the desert. 
        After landing, watch the ground crew professionally roll up the balloon envelope.
        After the adventure, your appetite works up so Gourmet Breakfast in a Traditional Bedouin Camp will be ready. 
        After capturing the sunrise and the scenic views of Margham Desert in your cameras and your minds forever, 
        upon landing you can engage in the UAE’s oldest traditional sport, ‘Falconry’.`,
        inclusions: `● Access to 4000 feet in Hot Air Ballon.
        ● Morning Refreshment Tea, Coffee, Kava, and dates. 
        ● Flight Certificate signed by the pilot.
        ● Pictures and videos of the gigantic UAE desert.
        ● Top-notch safety measures provided with safety gears.
        ● Balloon Ride duration 40 – 70 Minutes.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancel 72 hours prior from the date of activity for a partial refund.
        ● The approx flight take off timing is in between 5.30am – 6.30am (UAE time).
        ● Children aged between 0-5 years and adults over 70 years are not allowed.
        ● Passengers with weight over 120kg will be charged double the ticket price.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Helicopter Ride",
        image : require('../../assets/img/Dubai helicopter.jpg'),
        carouselImage1: require('../../assets/img/Dubai helicopter 1.jpg'),
        carouselImage2: require('../../assets/img/Dubai helicopter 2.jpg'),
        carouselImage3: require('../../assets/img/Dubai helicopter 3.jpg'),
        text : "Enjoy the famous view of Dubai from bird's eye in the helicopter ride",
        price: " 665",
        about: `Riding in a helicopter over the marvelous city of Dubai is absolutely thrilling. 
        You get to see a bird's eye view of the city and even take a closer look at some of the landmarks of Dubai. 
        Depending on the package you choose, you get to see Dubai like never before. 
        Burj Khalifa, Burj Al Arab, Palm Jumeirah, Hotel Atlantis the Palm, The World Island, Jumeirah Mosque, etc. are some of the important places that you should check out.
        While it is absolutely normal to get lost in the panoramic views of the city, do not forget to take out your camera and click some photos of what you see.`,
        inclusions: `● Falcon Pearl Helicopter Tour - 12 minutes ride.
        ● The Palm Jumeirah Island view. 
        ● Burj Khalifa view.
        ● Jumeirah Beach coastline view.
        ● The World Islands from its south pole.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancel 24 hours prior from the date of activity for a partial refund.
        ● Check-in time is 45 minutes prior to flight time and requested to present an original passport or official photo ID.`
    },
    {
        title : "Limousine Ride",
        image : require('../../assets/img/Dubai limousine.jpg'),
        carouselImage1: require('../../assets/img/Dubai limo 3.jpg'),
        carouselImage2: require('../../assets/img/Dubai limo 2.jpg'),
        carouselImage3: require('../../assets/img/Dubai limo 1.jpg'),
        text : "Enjoy the skyline with the luxurious limousine ride",
        price: " 450",
        about: `Your luxurious limousine ride starts with our super classy limousine at your disposal for one hour, you can craft your own travel program. 
        Your friendly chauffeur not only assures you of a regal ride but also provides an insider experience of exploring the region’s
        most significant attractions, from Burj Al Arab and Palm Jumeirah to Burj Khalifa, Dubai Marina, and Dubai Creek.`,
        inclusions: `● Access to the Luxurious Limousine.
        ● Private 1-hour Dubai tour by luxury stretch Limousine
        ● Soft Drinks. 
        ● Services of a friendly Chauffeur .
        ● Pick up & drop from your Hotel.`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancel 72 hours prior from the date of activity for a partial refund.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Yellow Boats Dubai",
        image : require('../../assets/img/Yellow boat.jpg'),
        carouselImage1: require('../../assets/img/Yellow boat 1.jpg'),
        carouselImage2: require('../../assets/img/Yellow boat 2.jpg'),
        carouselImage3: require('../../assets/img/Yellow boat 1.jpg'),
        text : "Enjoy the JBR skyline with the adventurous Yellow boats ride",
        price: " 140",
        about: `Yellow Boat tour with a cruise duration that lasts for 75-90 minutes. 
        Marvel at the panoramic views of opulent residences, glitzy resorts, and ultra-classy hotels, as you cruise at thrilling speed along Dubai Marina as well as the man-made islands of Palm Jumeirah. 
        You’ll also have the opportunity to see some of Dubai’s most legendary sights up-close, such as Burj Al Arab and Palm Atlantis Hotel. 
        Being this tour accompanied by a knowledgeable guide, you’ll be able to gain some interesting information on attractions covered in this delightful boat tour, from Dubai Marina Yacht Club
        and Infinity Tower to the famed JBR Walk.`,
        inclusions: `● Gain an easy entry to the largest water activity in dubai.
        ● With its unique water activity depicting the Legend of Lost Pearl, know about the country’s fascinating pearl diving heritage. 
        ● Photo Opportunity 
        ● Life Jackets
        ● Thrilling Boat Ride   
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancel 24 hours prior from the date of activity for a partial refund.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Atlantis Water Park",
        image : require('../../assets/img/Atlantis.jpeg'),
        carouselImage1: require('../../assets/img/Atlantis park 1.jpg'),
        carouselImage2: require('../../assets/img/Atlantis park 2.jpg'),
        carouselImage3: require('../../assets/img/Atlantis park 1.jpg'),
        text : "Access to largest water park located in iconic Palm island",
        price: " 299",
        about: `Atlantis Water Park or Atlantis Aquaventure is a water park located inside the premises of Hotel Atlantis, 
        The Palm, the crowning glory of Dubai's Palm Jumeirah & known to be the largest water park in Dubai.
        Atlantis Aquaventure is home to several thrilling rides and amazing experiences.  
        You get to access all the slides and rides of the water park and at the same time explore the aquarium as well as feed the sharks. 
        You can choose to lounge on the beach or indulge in some adventure water sports.
        Apart from this, there are several restaurants and eateries that serve delicious food.`,
        inclusions: `● Admission to one of Dubai’s fantastic water parks, Atlantis Aquaventure Day Pass.
        ● It’s a part of the luxurious ever Atlantis, The Palm resort which is located on the Palm island. 
        ● Find some amazing water rides, exhilarating slides and other amusing attractions and thrill levels.
        ● Your ticket also offers entry to Lost Chambers Aquarium where you can discover 65,000 marine creatures
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancel 24 hours prior from the date of activity for a partial refund.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Luxury Yacht",
        image : require('../../assets/img/Dubai yacht.jpg'),
        carouselImage1: require('../../assets/img/Dubai yacht 1.jpg'),
        carouselImage2: require('../../assets/img/Dubai yacht 2.jpg'),
        carouselImage3: require('../../assets/img/Dubai yacht 1.jpg'),
        text : "Enjoy the one hour sea-scape in the luxurious Dubai yacht",
        price: " 450",
        about: `We offer you a fully decked yacht and a friendly crew for your assistance on your one-hour escape, 
        revel in the splashing waves, sop up Arabian sunshine, and admire the most commanding views as you glide past the man-made island 
        of Palm Jumeirah, World Island, and attractions like Burj Al Arab as well as Atlantis, The Palm. 
        Feel the bliss in the serene and gleaming waters of the Arabian Gulf. Relax in the salty winds of the sea and unwind yourself to rejuvenate. 
        Our luxurious vessel will give you the comfort and class to enjoy the vast gulf.`,
        inclusions: `● Professional Crew.
        ● Cruising in Marina Lagoon. 
        ● Life jacket.
        ● Refreshments (Water and Soft Drinks)
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancel 72 hours prior from the date of activity for a partial refund.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Jebel Jais Zipline",
        image : require('../../assets/img/Zipline.jpg'),
        carouselImage1: require('../../assets/img/Zipline 1.jpg'),
        carouselImage2: require('../../assets/img/Zipline 2.jpg'),
        carouselImage3: require('../../assets/img/Zipline 1.jpg'),
        text : "Zip the longest zipline on the planet",
        price: " 360",
        about: `The Jebel Jais Zipline tour Stretching 2.83 km, this Zipline is the longest in the world. 
        If you are above the age of 12 and weigh more than 45 kg, this adventure trip is something that you would love.
        Jebel Jais, located in Ras Al Khaimah, is the largest mountain in the UAE. Apart from being a thrilling experience, 
        you would also be able to enjoy the picturesque beauty of the place. 
        Transportation facilities are provided if you choose the same at the time of booking.`,
        inclusions: `● Chance to experience the longest zipline on the planet.
        ● This is further most exhilarating way to admire the UAE’s tallest mountain.
        ● Enjoy this zipline adventure at an exhilarating speed of 120-150 kilometers per hour.
        ● The whole experience is absolutely with all safety gears and expert assistance made available.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancel 72 hours prior from the date of activity for a partial refund.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Yas Waterworld",
        image : require('../../assets/img/Yas waterworld.jpg'),
        carouselImage1: require('../../assets/img/Yas waterworld 1.jpg'),
        carouselImage2: require('../../assets/img/Yas waterworld 2.jpg'),
        carouselImage3: require('../../assets/img/Yas waterworld 1.jpg'),
        text : "Admission to the famous YAS waterpark - Abu Dhabi",
        price: " 245",
        about: `You haven’t been to a water park until you’ve been to Yas Waterworld, Water’s Greatest Playground.
        Spread over 37 acres, the UAE’s mega water park offers the ultimate water adventure where guests can enjoy more than 40 rip-roaring rides,
        slithering slides and amazing experiences at different thrill levels catering for the whole family.`,
        inclusions: `● General Admission ticket to Yas Waterworld.
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancel 48 hours prior from the date of activity for a partial refund.
        ● Outside food and beverages are not allowed inside.
        ● The Yas Waterworld timings are from 10:00 AM to 8:00 PM Monday - Friday and 10:00 AM to 7:00 PM Saturday and Sunday.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Dinner In The Sky",
        image : require('../../assets/img/Dinner in sky.jpg'),
        carouselImage1: require('../../assets/img/Dinner in sky 1.jpg'),
        carouselImage2: require('../../assets/img/Dinner in sky 1.jpg'),
        carouselImage3: require('../../assets/img/Dinner in sky 1.jpg'),
        text : "Enjoy dinner in the Sky at 50 meters above ground",
        price: " 650",
        about: `Dinner in the Sky is where you get to dine while floating 50 meters above the ground.
        Dinner in the Sky is located in Sky Dive Dubai, where a table for 22 people is suspended in the air with the help of a crane. 
        This unique restaurant serves lunch, dinner, and afternoon tea as well. 
        While you enjoy the 3-course meal served here, you get to check out the amazing attractions and landmarks of the city like the Palm Jumeirah, Burj Al Arab, Dubai Marina, etc.
        Dinner in the Sky offers 2 lunch sessions, 1 afternoon tea session, and 3 dinner sessions, each lasting 90 minutes. 
        During lunch and dinner, you would be served a starter, main course, and dessert, while during the afternoon tea session, you would be served with an array of savory and sweet snacks.`,
        inclusions: `● Dinner in the Sky entry tickets.
        ● The aerial culinary experience at a height of over 50 meters 
        ● Private/sharing Transfers (on additional charges),
        ● Duration 90 minutes.`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed.
        ● Lunch sessions: 2:00PM - 3:30PM and 3:30PM - 5:00PM. Afternoon Tea: 5:00PM - 6:30PM. Dinner: 6:30PM - 8:00PM & 8:00PM - 9:30PM.
        ● Please bring valid ID proof and ticket voucher.`
    },
    {
        title : "Xline Dubai Marina",
        image : require('../../assets/img/Xline dubai.jpg'),
        carouselImage1: require('../../assets/img/Xline dubai 1.jpg'),
        carouselImage2: require('../../assets/img/Xline dubai 2.jpg'),
        carouselImage3: require('../../assets/img/Xline dubai 1.jpg'),
        text : "Admission ticket to longest urban Zipline in the world",
        price: " 650",
        about: `If you want to enjoy a bird's eye view of Marina, you must opt for XLine Dubai Marina Zipline. 
        The XLine Dubai Marina Zipline is the longest urban Zipline in the world. 
        Here, you can either go ziplining solo or do it with someone, like your partner or friend. 
        Anyone between the ages of 12 and 65 and weighing between 50 and 100 kg can go zip lining here. 
        Your ziplining journey starts from Amwaj Towers, takes you across the Marina and ends at the Dubai Marina Mall. 
        During your journey, you would be able to look at some of the most gorgeous buildings of the area.`,
        inclusions: `● Admission ticket to Xline Marina.
        ● Safety gear
        ● Images and video recordings of your jump
        ● Private/sharing Transfers (on additional charges)`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancellation not allowed.
        ● The experience is not available for kids below 12 years age and those above 65 age.
        ● Pregnant women are not allowed for the ride.
        ● Please bring valid ID proof and ticket voucher.`
    },
       {
        title : "Burj Al Arab Tour",
        image : require('../../assets/img/Burj al arab.jpg'),
        carouselImage1: require('../../assets/img/Burj al arab 1.jpg'),
        carouselImage2: require('../../assets/img/Burj al arab 2.jpg'),
        carouselImage3: require('../../assets/img/Burj al arab 3.jpg'),
        text : "Get to see the most luxurious hotel in the world from inside",
        price: " 240",
        about: `Burj Al Arab is to be the most luxurious hotel in the world and dining experience at Burj Al Arab. 
        The 90-minute butler-guided tour will take guests on an exclusive journey of discovery, to hear the untold stories of Dubai’s world-famous hotel. 
        The tour includes a visit to the famous hotel atrium and glamourous Royal Suite as well as a curated Experience Suite with digital interactives. 
        Starting off at the wave-shaped Jumeirah Beach Hotel, guests will be driven by buggy to a photo op vantage point midway over the bridge that connects the hotel to the mainland.`,
        inclusions: `● Admission ticket to Burj Al Arab.
        ● 90 minutes guided tour.
        ● Soft beverages or Premium beverage at UMA Sunset Lounge(additional charges)
        ● Private/sharing Transfers (on additional charges),
        ● Duration 90 minutes.`,
        thingsToRemember: `● This is per person estimate. Prices will be lesser for couple or group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.
        ● Cancel 24 hours prior from the date of activity for a partial refund.
        ● The timings are from 10:30 AM – 7:30 PM everyday.
        ● Please bring valid ID proof and ticket voucher.`
    },
]