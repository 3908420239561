import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import video from '../../assets/img/Banner-video.mp4';
import "./Carousel.css";

function MyCarousel(props) {
    return(
        <Carousel showThumbs={false} autoPlay interval={3000} infiniteLoop stopOnHover={false} showStatus={false}>
        <div>
            <img alt="" src={props.pic1} />
        </div>
        <div>
            <img alt="" src={props.pic2} />
        </div>
        <div>
            <img alt="" src={props.pic3} />
        </div>
    </Carousel>
    )
}

export default MyCarousel;


export function HomeCarousel(props){
    const [intervalz, setIntervalz] = useState(7000);

    const onChange = (index, item) => {
        setIntervalz(item.props["data-interval"]);
      };

    return (
        <Carousel onChange={onChange} showThumbs={false} autoPlay interval={intervalz} infiniteLoop={true} stopOnHover={false} showStatus={false} showIndicators={false} showArrows={false}>
        <div data-interval={7000}>
            <img alt="" src={props.pic1} />
        </div>
        <div data-interval={16000}>
            <img alt="" src={props.pic2} />
        </div>
        <div data-interval={7000} dangerouslySetInnerHTML={{ __html: `
        <video class="carouselVideo" playsinline autoplay loop muted>
            <source src=${video} type="video/mp4"/>
        </video>`}}
    />
    </Carousel>
    )
  }

