import React,{ useState,useEffect }  from 'react';
import MyCarousel from '../Carousel/Carousel';
import './Modal.css';
import Logo from '../../assets/img/contact.png';
import Select from 'react-select';

const options = [
  { value: '1', label: 'Solo' },
  { value: '2', label: 'Couple' },
  { value: '3', label: 'Trio' },
  { value: '4', label: 'Fantastic 4' },
  { value: '5', label: 'High 5'},
  { value: '6', label: '6ix Squad'},
  { value: '7', label: 'Lucky 7'},
  { value: '8', label: 'Elite 8'},
  { value: '9', label: 'Cloud 9'},
  { value: '10', label: 'Perfect 10'}
];

const customStyles = {
  option: (styles) => ({
    ...styles,
    zIndex: 1,
    color: 'black',
    cursor: 'pointer'
}),

control: (provided, state) => ({
  ...provided,
  background: 'black',
  borderColor: state.isFocused ? "green" : "white",
}),
menu: base => ({
  ...base,
  zIndex: 100
}),
singleValue: provided => ({
  ...provided,
  color: 'white'
})
};


export function ChildModal(props) {

  const inputInitialDetails = {
    name: '',
    number: '',
    email: '',
    title: '',
    date: '',
    count: '',
    price: ''
  }


  const [input, setInput] = useState(inputInitialDetails);
  const [status, setStatus] = useState({});
  const [buttonText, setButtonText] = useState('Send');

  if (!props.show){
    return null
  }


  function handleChange(event){
    // const {name, value} = event.target;
    const name = event.target.name;
    const value = event.target.value;

    setInput(prevInput => {
      return {
        ...prevInput,
        [name]: value,
        title: props.data[0],
        date: props.date,
        count: props.count,
        price: props.price
      }
    })
  }
  console.log(props.date);
  
  const handleClick = async(event) => {
    event.preventDefault();
    setButtonText("Sending...");
    const cusData ={
      name: input.name,
      number: input.number,
      email: input.email,
      title: input.title,
      date: input.date,
      count: input.count,
      price: input.price
    }

// https://nodeback.tripaway.ae/enquiry
//http://localhost:5000/enquiry
    let response = await fetch("https://nodeback.tripaway.ae/enquiry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(cusData),
    });

    setButtonText("Sent");
    let result = await response.json();
    setInput(inputInitialDetails);
    if (result.code === 200) {
      setStatus({ succes: true, message: "BOOM! We've received & will contact you shortly."});
    } else {
      setStatus({ succes: false, message: 'Something is wrong, please try again later.'});
    }
  }


  return (
      <div className='childModal' onClick={props.onClose}>
      <div className='childModal-content' onClick={e => e.stopPropagation()}>
        <img className='childModal-img' alt='' src={Logo}/>
        <h5 className='childModal-close' onClick={props.onClose}>&#10006;</h5>
        <div className='childModal-inputs'>
        <div className='childModal-contacts'>
        <a className="childModal-link" href={`https://wa.me/971567107636?text=Hello!+I+have+an+enquiry+regarding+${props.data[0]}+😀`}><i className="fa-brands fa-whatsapp modalIconColor"></i> Book via WhatsApp</a>
        <a className="childModal-link" href='tel:+971567107636'><i className="fa-sharp fa-solid fa-phone modalIconColor"></i> Book via Call</a>
        </div>
        <p className='childModal-or'>Or</p>
          <p className='childModal-form'>Let Us Call You Back</p>
          <form onSubmit={handleClick} className='cusDetails'>
          <input onChange={handleChange} name='name' value={input.name}  className='cusDetails-input' placeholder='Name' required autoComplete='off'/>
          <input onChange={handleChange} name='number' value={input.number} className='cusDetails-input' placeholder='Contact Number' required autoComplete='off'/>
          <input onChange={handleChange} name='email' value={input.email} className='cusDetails-input' placeholder='Email' required autoComplete='off'/>
          <button className='cusDetails-btn' type='submit'><span>{buttonText}</span></button>
          {
            status.message &&
            <div>
              <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
            </div>
          }
          </form>
          </div>
          </div>
          </div>
  );
}



export default function MyModal(props) {

  const [show, setShow] =useState(false);
  const [status, setStatus] = useState({});
  const today = new Date().toISOString().substr(0, 10);
  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedOption, setSelectedOption] = useState(null);
  const [price, setPrice] = useState(props.data[6]);
  const [count, setCount] = useState(0);
 
  useEffect(() => {
    setPrice(props.data[6]);
  }, [props.data]);


  function handleOpenModal() {
    setShow(true);
    // console.log(item)
  }

  function onClose(){
    setShow(false);
  }

  function dateChange(event){
    const date =  event.target.value;
    if (date < today){
      setSelectedDate(today);
    } else{
      setSelectedDate(date);
    }
  }

  function updatePrice(options){
    // console.log(options.value)
    const value =options.value;
    setSelectedOption(options);
    setStatus({});
    setPrice(value*props.data[6])
  }

  function modalClose(){
    setSelectedOption(null);
    setStatus({});
    setSelectedDate(today);
    props.onClose();
  }

  function handleClick(event){
    if (!selectedOption) {
      setStatus({ succes: true, message: 'Oh! You might have missed this! 👇🏿'});
    } else {
      setCount(selectedOption.value);
      setShow(true);
    }
  }

  if (!props.show){
    return null
  }

  return (
    <div className='myModal' onClick={modalClose}>
        <div className='myModal-content' onClick={e => e.stopPropagation()}>
        <div>
        <h5 className='modalTitle'>{props.data[0]}<p className='myModal-close' onClick={modalClose}>&#10006;</p></h5>
        </div>
        <div className="carousell">
        <MyCarousel pic1={props.data[2]} pic2={props.data[3]} pic3={props.data[4]}/>
        </div>
        <div>
         <p className='modalText'>{props.data[5]}</p>
        </div>
        <div>

      
        <p className='date-text'>Pick a Date<input required className='calender' type="date" id="date" name="date" min={today} value={selectedDate} onChange={dateChange}></input></p>
        {props.data[10] ? (<div><button className='myModal-btn' onClick={handleOpenModal} data={props.data}>Book @ AED {props.data[6]}* </button></div>) : 
        (
        <div>
        {
          status.message &&
          <div>
            <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
          </div>
        }
        <Select required className='selectInput' defaultValue={selectedOption} placeholder={<div>Select Headcount</div>}  onChange={updatePrice} value={selectedOption} options={options} styles={customStyles}/>
        <button className='myModal-btn' onClick={handleClick} data={props.data}>Book @ AED {price}* </button>
        </div>
        )}
   
        <ChildModal onClose={onClose} price={price} date={selectedDate} count={count} data={props.data} show={show}/>
     
        </div>
        
        <div>
        <h5 className='modalAboutHead'>About tour:</h5>
        <p className='modalAbout'>{props.data[7]}</p>
        </div>
        <div>
        <h5 className='modalInclusionHead'>Tour inclusions:</h5>
        <p className='modalInclusion'>{props.data[8]}</p>
        </div>
        <div>
        <h5 className='modalInclusionHead'>*Things to remember:</h5>
        <p className='modalInclusion'>{props.data[9]}
        </p>
        </div>
        </div>
    </div>
  );
}

// export function ContactModal(props){

//   if (!props.show){
//     return null
//   }

//   return (
//     <div className='contactModal' onClick={props.onClose}>
//     <div className='contactModal-content' onClick={e => e.stopPropagation()}>
//     <img className='logoB' alt='' src={LogoB}/>
//     <div>
//     <div className='contactModal-header'>
//     <h2>Get in Touch</h2>
//     </div>
//     <div className='contactModal-body'>
//     <p><i class="fa-solid fa-phone"></i> &nbsp;&nbsp; &nbsp;&nbsp;+971565319527</p>
//     <br></br>
//     <p><i class="fa-brands fa-whatsapp"></i> &nbsp;&nbsp; &nbsp;&nbsp;+971565319527</p>
//     <br></br>
//     <p><i class="fa-solid fa-envelope"></i> &nbsp;&nbsp; &nbsp;&nbsp;support@tripaway.ae</p>
//     </div>
//     </div>
//     </div>
//     </div>
//   )
// }
