import React  from 'react';
import "./SmallCard.css";




function SmallCard(props){
    return (
          <div>
            <div className="card" key={props.index}>
            <img alt="" src={props.image} />
            <div className="card-body">
              <h2>{props.title}</h2>
              <h5>{props.text}</h5>
            </div>
            <button className='smallCard-btn'>From {props.price} AED</button>
          </div>
        </div>

    )
}

export default SmallCard;

