import React,{useState} from 'react';
import SmallCard from '../components/Cards/SmallCard';
import './Styles.css';
import {SmallCardData} from '../components/Cards/SmallCardData';
import MyModal from '../components/Modal/Modal';
import headerImage from '../assets/img/marina yacht.jpg';
import colorSharp from "../assets/img/color-sharp.png";

function Activities(){
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [show, setShow] =useState(false);

    function handleOpenModal(item) {
        setShow(true);
        document.body.style.overflow = "hidden";
        setData([item.title, item.image, item.carouselImage1, item.carouselImage2, item.carouselImage3, item.text, item.price, item.about, item.inclusions, item.thingsToRemember])
      }

      function onClose(){
        setShow(false);
        document.body.style.overflowY = "scroll";
      }

    return(
        <div className='routesDiv'>
        <img className='headerImage' src={headerImage} alt=''/>
        <div className='header'>
        <p className='header-text'>Things to do in Dubai</p>
        </div>
      
        <input className='searchBar' type="text" placeholder='🔍 Search here...' onChange={(e) => {setSearch(e.target.value)}}/>
      
        <div className='card-div'>
        {SmallCardData.filter((item, index) => {
            if(search === ""){
             return item
            } else if(item.title.toLowerCase().includes(search.toLowerCase())){
              return item
            } return false;
        }).map((item, index) => {
            return(
            <div key={index} value={item.title} onClick={() => handleOpenModal(item)}>
            <SmallCard  index={index}
            title={item.title} image={item.image} carouselImage1={item.carouselImage1} carouselImage2={item.carouselImage2} carouselImage3={item.carouselImage3}
            text={item.text} price={item.price} about={item.about} inclusions={item.inclusions} thingToRemember={item.thingsToRemember} />
            </div>
            )
        })}
        </div>
        <img className="background-image-package" src={colorSharp} alt="" />
        <MyModal onClose={onClose} data={data} show={show}></MyModal>
        </div>
    )
}

export default Activities;