
export const PackageCardsData = [
    {
        title : "5 Days 4 Nights - Basic Package",
        image : require('../../assets/img/Dubai package 1.jpg'),
        carouselImage1: require('../../assets/img/Dubai package C1.jpg'),
        carouselImage2: require('../../assets/img/Dubai package C2.jpg'),
        carouselImage3: require('../../assets/img/Dubai package C3.jpg'),
        text : "Including hotel, visa and activities like Dubai city tour, Marina Cruise, Global village & Burj Khalifa etc..",
        price: " 3096/Couple",
        about: `Day 1: After picking up from airport you will start your day with Dubai city tour including Dubai Mall & Burj khalifa tour.
        Day 2: On this day you will go in an adventure trip of Desert Safari including Dune bashing, BBQ dinner, Camel ride, Belly dance etc.
        Day 3: On the third day you will get a chance to see the Dubai Creek through Creek cruise including BBQ dinner.
        Day 4: Visit the world's largest floral garden, Miracle garden & Global village on a shopping spree of all countries.
        Day 5: Leisure and transfer to airport`,
        inclusions: `● 4 star hotel stay with breakfast.
        ● Dubai city and Dubai mall tour inlcuding Dubai fountain & Burj Khalifa lighting show.
        ● Entry ticket and access to the Top Level(124-125th) of Burj Khalifa. 
        ● Desert Safari with BBQ dinner.
        ● Creek cruise with buffet dinner.
        ● Global Village tickets.
        ● Miracle garden tickets.
        ● Airport & all transfers included.
        ● 30 days tourist visa including insurance.`,
        thingsToRemember: `● This is per couple estimate. Prices will be lesser for group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.`
    },
    {
        title : "6 Days 5 Nights - Economy Package",
        image : require('../../assets/img/Dubai package 2.jpg'),
        carouselImage1: require('../../assets/img/Dubai package C4.jpg'),
        carouselImage2: require('../../assets/img/Dubai package C5.jpg'),
        carouselImage3: require('../../assets/img/Dubai package C6.jpg'),
        text : "Including hotel, visa and activities like Dubai city tour, Abu Dhabi city tour, Marina Cruise, Dubai Frame & Burj Khalifa etc..",
        price: " 3375/Couple",
        about: `Day 1: After picking up from airport you will start your day with Dubai city tour including Dubai Mall & Burj khalifa tour.
        Day 2: On this day you will go in an adventure trip of Desert Safari including Dune bashing, BBQ dinner, Camel ride, Belly dance etc.
        Day 3: On the third day you will get a chance to see the mesmerizing Dubai Marina through Marina cruise including BBQ dinner.
        Day 4: Visit to the Dubai Frame, which shows the history and modern views of Dubai at 150m height.
        Day 5: Abu Dhabi city tour including Grand Mosque, Emirates palace, ferrari world view, corniche etc.
        Day 6: Leisure and transfer to airport`,
        inclusions: `● 4 star hotel stay with breakfast.
        ● Dubai city and Dubai mall tour inlcuding Dubai fountain & Burj Khalifa lighting show.
        ● Entry ticket and access to the Top Level(124-125th) of Burj Khalifa.
        ● Desert Safari with BBQ dinner.
        ● Marina cruise with buffet dinner.
        ● Dubai frame tickets.
        ● Abu Dhabi city tour including grand mosque, ferrari world view, corniche, emirates palace etc.
        ● Airport & all transfers included.
        ● 30 days tourist visa including insurance.`,
        thingsToRemember: `● This is per couple estimate. Prices will be lesser for group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.`
    },
    {
        title : "7 Days 6 Nights - Premium Package",
        image : require('../../assets/img/Dubai package 3.jpg'),
        carouselImage1: require('../../assets/img/Dubai package C7.jpg'),
        carouselImage2: require('../../assets/img/Dubai package C8.jpg'),
        carouselImage3: require('../../assets/img/Dubai package C9.jpg'),
        text : "Including hotel, visa and activities like Dubai city tour, Abu Dhabi city tour, Marina Cruise, Dubai Frame & Burj Khalifa etc..",
        price: " 3966/Couple",
        about: `Day 1: After picking up from airport you will start your day with Dubai city tour including Dubai Mall, Burj khalifa & Dubai Aquarium tour.
        Day 2: On this day you will go in an adventure trip of Desert Safari including Dune bashing, BBQ dinner, Camel ride, Belly dance etc.
        Day 3: On the third day you will get a chance to see the mesmerizing Dubai Marina through Marina cruise including BBQ dinner.
        Day 4: Visit to the Dubai Frame, which shows the history and modern views of Dubai at 150m height.
        Day 5: Abu Dhabi city tour including Grand Mosque, Emirates palace, ferrari world view, corniche etc.
        Day 6: On this day visit the world's largest spectacular floral garden, Miracle garden.
        Day 7: Leisure and transfer to airport`,
        inclusions: `● 4 star hotel stay with breakfast.
        ● Dubai city and Dubai mall tour inlcuding Dubai fountain & Burj Khalifa lighting show.
        ● Dubai mall Aquarium & under water zoo.
        ● Entry ticket and access to the Top Level(124-125th) of Burj Khalifa.
        ● Desert Safari with BBQ dinner.
        ● Marina cruise with buffet dinner.
        ● Dubai frame tickets.
        ● Miracle garden tickets.
        ● Abu Dhabi city tour including grand mosque, ferrari world view, corniche, emirates palace etc.
        ● Airport & all transfers included.
        ● 30 days tourist visa including insurance.`,
        thingsToRemember: `● This is per couple estimate. Prices will be lesser for group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.`
    },
    {
        title : "7 Days 6 Nights - Luxury Package",
        image : require('../../assets/img/Dubai package 4.jpg'),
        carouselImage1: require('../../assets/img/Dubai package C10.jpg'),
        carouselImage2: require('../../assets/img/Dubai package C2.jpg'),
        carouselImage3: require('../../assets/img/Dubai package C3.jpg'),
        text : "Including 5* hotel, visa and activities like Dubai city tour, Abu Dhabi city tour, Marina Cruise, Dubai Frame & Burj Khalifa etc..",
        price: " 4790/Couple",
        about: `Day 1: After picking up from airport you will start your day with Dubai city tour including Dubai Mall, Burj khalifa & Dubai Aquarium tour.
        Day 2: On this day you will go in an adventure trip of Desert Safari including Dune bashing, BBQ dinner, Camel ride, Belly dance etc.
        Day 3: On the third day you will get a chance to see the mesmerizing Dubai Marina through Marina cruise including BBQ dinner.
        Day 4: Visit to the Dubai Frame, which shows the history and modern views of Dubai at 150m height following Museum of the future(world's most beautiful building).
        Day 5: Abu Dhabi city tour including Grand Mosque, Emirates palace, ferrari world view, corniche etc.
        Day 6: On this day visit the world's largest spectacular floral garden, Miracle garden follwing View at the Palm island.
        Day 7: Leisure and transfer to airport`,
        inclusions: `● 5 star hotel stay with breakfast.
        ● Dubai city and Dubai mall tour inlcuding Dubai fountain & Burj Khalifa lighting show.
        ● Dubai mall Aquarium & under water zoo.
        ● Entry ticket and access to the Top Level(124-125th) of Burj Khalifa.
        ● Desert Safari with BBQ dinner.
        ● Marina cruise with buffet dinner.
        ● Dubai frame tickets.
        ● View at the Palm island tickets.
        ● Miracle garden tickets.
        ● Museum of the Future tickets.
        ● Abu Dhabi city tour including grand mosque, ferrari world view, corniche, emirates palace etc.
        ● Airport & all transfers included.
        ● 30 days tourist visa including insurance.`,
        thingsToRemember: `● This is per couple estimate. Prices will be lesser for group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.`
    },
    {
        title : "8 Days 7 Nights - VIP Package",
        image : require('../../assets/img/Dubai package 5.jpg'),
        carouselImage1: require('../../assets/img/Dubai package C6.jpg'),
        carouselImage2: require('../../assets/img/Dubai package C5.jpg'),
        carouselImage3: require('../../assets/img/Dubai package C4.jpg'),
        text : "Including 5* luxurious hotel, visa and activities like Dubai city tour, Abu Dhabi city tour, Marina Cruise, Helicopter ride & Burj Khalifa etc..",
        price: " 6992/Couple",
        about: `Day 1: After picking up from airport you will start your day with Dubai city tour including Dubai Mall, Burj khalifa & Dubai Aquarium tour.
        Day 2: On this day you will go in an adventure trip of Desert Safari including Dune bashing, BBQ dinner, Camel ride, Belly dance etc.
        Day 3: On the third day you will get a chance to see the mesmerizing Dubai Marina through Marina cruise including BBQ dinner.
        Day 4: Visit to the Dubai Frame, which shows the history and modern views of Dubai at 150m height following Museum of the future(world's most beautiful building).
        Day 5: Abu Dhabi city tour including Grand Mosque, Emirates palace, ferrari world view, corniche etc.
        Day 6: On this day visit the Skyview of Address hotel follwing with View at the Palm island.
        Day 7: On this day you will get a chance to see the whole Dubai with the famous 15mins Helicopter ride following with gigantic Miracle garden.
        Day 8: Leisure and transfer to airport`,
        inclusions: `● 5 star luxurious hotel stay with breakfast.
        ● Dubai city and Dubai mall tour inlcuding Dubai fountain & Burj Khalifa lighting show.
        ● Dubai mall Aquarium & under water zoo.
        ● Entry ticket and access to the Top Level(124-125th) of Burj Khalifa.
        ● Desert Safari with BBQ dinner.
        ● Marina cruise with buffet dinner.
        ● Dubai frame tickets.
        ● View at the Palm island tickets.
        ● Miracle garden tickets.
        ● Museum of the Future tickets.
        ● Helicopter ride tickets.
        ● Address Skyview tickets.
        ● Abu Dhabi city tour including grand mosque, ferrari world view, corniche, emirates palace etc.
        ● Airport with 3 days private transfers.
        ● 30 days tourist visa including insurance.`,
        thingsToRemember: `● This is per couple estimate. Prices will be lesser for group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.`
    },
    {
        title : "7 Days 6 Nights - Adventure Package",
        image : require('../../assets/img/Dubai package 6.jpg'),
        carouselImage1: require('../../assets/img/Dubai package C11.jpg'),
        carouselImage2: require('../../assets/img/Dubai package C12.jpg'),
        carouselImage3: require('../../assets/img/Dubai package C13.jpg'),
        text : "Including 5* hotel, visa and activities like Dubai city tour, Abu Dhabi city tour, Marina Cruise, Jebel Jais tour & Scuba diving etc..",
        price: " 6768/Couple",
        about: `Day 1: After picking up from airport you will start your day with Dubai city tour including Dubai Mall, Burj khalifa & Dubai Aquarium tour.
        Day 2: On this day you will go in an adventure trip of Desert Safari including Dune bashing, BBQ dinner, Camel ride, Belly dance etc.
        Day 3: On the third day you will get a chance to see the mesmerizing Dubai Marina through Marina cruise including BBQ dinner.
        Day 4: You will visit Hatta & Jebel Jais, the mountain range of UAE. With awesome scenic beauty you will have campning & BBQ food. Additionally you will do the world's tallest and longest ZIP line over the mountain. 
        Day 5: Abu Dhabi city tour including Grand Mosque, Emirates palace, ferrari world view, corniche etc.
        Day 6: On this day you will enjoy some water sport like Kayaking dubai & scuba diving in Marina with spectacular view.
        Day 7: Leisure and transfer to airport`,
        inclusions: `● 5 star hotel stay with breakfast.
        ● Dubai city and Dubai mall tour inlcuding Dubai fountain & Burj Khalifa lighting show.
        ● Dubai mall Aquarium & under water zoo.
        ● Entry ticket and access to the Top Level(124-125th) of Burj Khalifa.
        ● Desert Safari with BBQ dinner.
        ● Marina cruise with buffet dinner.
        ● Jebel jais mountain tour and camping with food.
        ● Hatta tour.
        ● World's tallest and longest zip line.
        ● Kayaking in dubai.
        ● Scuba diving.
        ● Abu Dhabi city tour including grand mosque, ferrari world view, corniche, emirates palace etc.
        ● Airport & all transfers included.
        ● 30 days tourist visa including insurance.`,
        thingsToRemember: `● This is per couple estimate. Prices will be lesser for group bookings.
        ● Prices are tentative & may vary subject to season timings & availability.
        ● Activity tickets are subject to availability.`
        
    },

 
]