
export const VisaCardData = [
    {
        title : "UAE Transit Visa 48 Hours",
        price: "150"
    },
    {
        title : "UAE Visit Visa 30 Days(Single entry)",
        price: "330"
    },
    {
        title : "UAE Visit Visa 60 Days(Single entry)",
        price: "490"
    },
    {
        title : "UAE Visit Visa 30 Days(Multiple entry)",
        price: "555"
    },
    {
        title : "UAE Visit Visa 60 Days(Multiple entry)",
        price: "710"
    },
]