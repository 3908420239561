import React,{useState} from 'react';
import './Styles.css';
import PackageCards from '../components/PackageCards/PackageCards';
import { PackageCardsData } from '../components/PackageCards/PackageCardsData';
import MyModal from '../components/Modal/Modal';
import headerImage from '../assets/img/Museum of future cover.jpg';
import colorSharp from "../assets/img/color-sharp.png";

function Packages(){
    const [data, setData] = useState([]);
    const [show, setShow] =useState(false)

    function handleOpenModal(item) {
        setShow(true);
        document.body.style.overflow = "hidden";
        setData([item.title, item.image, item.carouselImage1, item.carouselImage2, item.carouselImage3, item.text, item.price, item.about, item.inclusions, item.thingsToRemember ,'package']);
      }

      function onClose(){
        setShow(false);
        document.body.style.overflowY = "scroll";
      }

    return(
        <div className='routesDiv'>
        <img className='headerImage' src={headerImage} alt=''/>
        <div className='header'>
        <p className='header-text'>Tour Packages</p>
        </div>
       <div className='customPackage'> <a className='visaBtn customPackageLink' href="https://api.whatsapp.com/send?phone=971567107636&text=Hey!+I+want+a+customized+package+to+Trip+Away!+😀"><i className="fa-brands fa-whatsapp fa-lg"></i> &nbsp;Create your own package</a></div>
      
        <div className='packages-div'>
        {PackageCardsData.filter((item, index) => index < 8).map((item, index) => {
            return(
            <div key={index} onClick={() => handleOpenModal(item)}>
            <PackageCards key={index} item={item} index={index} packageRoute='packageRoute'
            title={item.title} image={item.image} carouselImage1={item.carouselImage1} carouselImage2={item.carouselImage2} carouselImage3={item.carouselImage3}
            text={item.text} price={item.price} about={item.about} inclusions={item.inclusions} thingToRemember={item.thingsToRemember} />
            </div>
            )
        })}
        </div>
        <img className="background-image-package" src={colorSharp} alt="" />
        <MyModal onClose={onClose} data={data} show={show}/>
        </div>
    )
}

export default Packages;

