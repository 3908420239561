// import { useState, useEffect } from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { ActivityComp } from "./components/ActivityComp";
import { PackageComp } from "./components/PackageComp";
import { ContactForm } from "./components/ContactForm";
import { Footer } from "./components/Footer";
import Packages from './routes/Packages';
import Activities from './routes/Activities';
import Visa from './routes/Visa';

// import { ColorRing } from  'react-loader-spinner';

function App() {
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   window.onload = () => {
  //     setLoading(true);
  //   };
  // }, []);


  return (
    <div className="App">
    <NavBar />
    <Routes>
    <Route path="/" element={ <div> <Banner /> <ActivityComp /> <PackageComp /> <ContactForm /> </div>}/>
    <Route path='/packages' element={ <Packages/>}/>
    <Route path='/activities' element={ <Activities/>}/>
    <Route path='/visa' element={ <Visa/>}/>
    </Routes>
    <Footer />
    </div>
  );
}

export default App;


// <div className="App">
// {loading ? (
// <div>
// <NavBar />
// <Routes>
// <Route path="/" element={ <div> <Banner /> <ActivityComp /> <PackageComp /> <ContactForm /> </div>}/>
// <Route path='/packages' element={ <Packages/>}/>
// <Route path='/activities' element={ <Activities/>}/>
// <Route path='/visa' element={ <Visa/>}/>
// </Routes>
// <Footer />
// </div>) : (
// <div className='loader'>
// <ColorRing
// visible={true}
// height="100"
// width="100"
// ariaLabel="blocks-loading"
// wrapperStyle={{}}
// wrapperClass="blocks-wrapper"
// colors={['#00ffe1', '#4A2FBD', '#AA367C', '#000','#00ffe1']}
// />
// </div>
//   )}
// </div>