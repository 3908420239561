import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/TripAway logo.png';
import navIcon1 from '../assets/img/nav-icon4.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
      <Navbar  style={{ maxHeight: '100px' }} expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img  width="70px" height="auto" className="img-responsive logo" src={logo} alt="Logo"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link href="/packages" className={activeLink === 'packages' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('packages')}>Tour Packages</Nav.Link>
              <Nav.Link href="/activities" className={activeLink === 'activities' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('activities')}>Activities</Nav.Link>
              <Nav.Link href="/visa" className={activeLink === 'visa' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('visa')}>Visa</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://api.whatsapp.com/send?phone=971567107636&text=Hello!+I+need+a+Trip+Away!+😀"><img src={navIcon1} alt="" /></a>
                <a href="https://www.facebook.com/profile.php?viewas=100000686899395&id=100090499230842"><img src={navIcon2} alt="" /></a>
                <a href="https://www.instagram.com/lets_tripaway/"><img src={navIcon3} alt="" /></a>
              </div>
              <HashLink to='/#connect'>
                <button className="vvd"><span>Let’s Connect</span></button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )
}
