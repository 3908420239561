import React from 'react';
import "./VisaCard.css";

function VisaCard(props) {
    return (
            <div className="grid" key={props.index}>
                <div className="visaCard">
                    <span className="plan-details">
                        <span className="plan-type">{props.title}</span>
                        <span className="plan-cost">AED {props.price}</span>
                    </span>
                </div>
            </div>

    )
}

export default VisaCard;