import React from 'react';
import "./PackageCards.css";



function PackageCards(props){
    return (
            <div className="packageCard" key={props.index}>
            <img alt="" src={props.image} />
            <div className="packageCard-body">
              <h2>{props.title}</h2>
              <h5>{props.text}</h5>
            </div>
            <button className='packageCard-btn'>From AED{props.price}</button>
          </div>
    )
}

export default PackageCards;
