import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import PackageCards from "./PackageCards/PackageCards";
import { PackageCardsData } from "./PackageCards/PackageCardsData";
import { VisaCardData } from '../components/VisaCard/VisaCardData';
import VisaCard from '../components/VisaCard/VisaCard';

export const PackageComp = () => {

//   function handleScroll(){
//     window.scrollTo(0, 0);
// };

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Dubai Tour Packages & Visas</h2>
                <p>Get the awesome experience of UAE using the top tour packages for United Arab Emirates. Splash the cash on a shopping trip, want to unwind on a luxurious sky trip, relax on a 5 star staycation or something picturesque just get it all here.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tour Packages</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">UAE Visas</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    
                  <Tab.Pane eventKey="first">
                    <a className='packages-button' href='/packages'>Check all UAE Tour Packages here</a>
                      <div className="package-div">
                      {PackageCardsData.filter((filteredItem, index) => index < 3).map((item, index) => {
                        return(
                          <PackageCards  key={index} item={item} index={index} image={item.image}
                          title={item.title} text={item.text} price={item.price}/>
                        )
                    })}
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                     <a className='packages-button' href='/visa'>Check all UAE Visas here</a>
                     <div className="package-div">
                     {VisaCardData.filter((item, index) => index < 3).map((item, index) => {
                      return(
                      <VisaCard key={index} item={item} index={index}  
                      title={item.title} price={item.price}/>
                      )
                  })}
                  </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt=''></img>
    </section>
  )
}
