import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import {HomeCarousel} from "./Carousel/Carousel";
import bannerImg from "../assets/img/dubai.jpg";
import Dubai from '../assets/img/Dubai-banner2.jpg';


export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "330+ World Records", "Mesmerizing Skyscrapers", "City of Gold", "Luxurious Hotels", "Exotic Beaches" ];
  const period = 2000;


 useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(50);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
    <div className="bannerImg">
    <HomeCarousel pic1={bannerImg} pic2={Dubai}/>
    </div>
      <Container>
        <Row className="aligh-items-center banner-header">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h3 className="bannerH3">{`Habibi! Welcome to Dubai...`} <span className="txt-rotate" data-rotate='[ "330+ World Records", "Mesmerizing Skyscrapers", "City of Gold", "Luxurious Hotels", "Exotic Beaches" ]'><span className="wrap">{text}</span></span></h3>
                <a style={{textDecoration: "none"}} href='/packages'><button className="exploreBtn">Explore Dubai <ArrowRightCircle size={25} /></button></a>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
