import React, { useState } from 'react';
import { VisaCardData } from '../components/VisaCard/VisaCardData';
import './Styles.css';
import headerImage from '../assets/img/Visa cover.jpg';
import { ChildModal } from '../components/Modal/Modal';
import Select from 'react-select';

const options = [
    { value: '1', label: 'Solo' },
    { value: '2', label: 'Couple' },
    { value: '3', label: 'Trio' },
    { value: '4', label: 'Fantastic 4' },
    { value: '5', label: 'High 5'},
    { value: '6', label: '6ix Squad'},
    { value: '7', label: 'Lucky 7'},
    { value: '8', label: 'Elite 8'},
    { value: '9', label: 'Cloud 9'},
    { value: '10', label: 'Perfect 10'}
  ];

const customStyles = {
    option: (styles) => ({
      ...styles,
      zIndex: 1,
      color: 'black',
      cursor: 'pointer'
  }),
  
  control: (provided, state) => ({
    ...provided,
    background: 'black',
    borderColor: state.isFocused ? "green" : "white",
  }),
  menu: base => ({
    ...base,
    zIndex: 100
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white'
  })
  };

function Visa(){
    const [data, setData] = useState(['UAE Transit Visa 48 Hours','150']);
    const [show, setShow] =useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [count, setCount] = useState(0);
    const [status, setStatus] = useState({});
    const [price, setPrice] = useState(150);
    const [totalPrice, setTotalPrice] = useState(150);
    const currentDate = new Date().toLocaleDateString(); 

    function handleClick(item){
        setData([item.title, item.price]);
        setPrice(item.price);
        setStatus({});
        setSelectedOption(null);
    }


    function handleOpenModal(data) {
        if (!selectedOption) {
            setStatus({ succes: true, message: 'Oh! You might have missed this! 👇🏿'});
          } else {
            setCount(selectedOption.value);
            setData([data[0], data[1]]);
            setShow(true);
            document.body.style.overflow = "hidden";
          }
      }

      function updateCount(options){
        setSelectedOption(options);
        const value =options.value;
        setTotalPrice(value*price)
        setStatus({});
      }
    

      function onClose(){
        setShow(false);
        document.body.style.overflowY = "scroll";
      }

    return(
        <div className='routesDiv'>
        <img className='headerImage' src={headerImage} alt=''/>
        <div className='header'>
        <p className='header-text'>UAE Tourist Visa</p>
        </div>
        <div className='visa-block'>
        {VisaCardData.filter((item, index) => index < 7).map((item, index) => {
            return(
            <div className='visaBtn' key={index} onClick={() => handleClick(item)} >
            {item.title}
            </div>
            )
        })}
        </div>
     
        <div className='visa-details'>
            <h2 className='visaDetailsHeader'>{data[0]}</h2>
            <h3 className='visaCost'>AED {data[1]}</h3>
            <div className='visaPoints'>
            <p>&#10004; Embassy fees</p>
            <p>&#10004; Medical Insurance</p>
            </div>
            {
                status.message &&
                <div className='visaMessage'>
                  <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                </div>
              }
            <Select className='visaInput' defaultValue={selectedOption} placeholder={<div>Select</div>} onChange={updateCount} value={selectedOption} options={options} styles={customStyles}/>
            <button className='visa-btn' onClick={() => handleOpenModal(data)}>Apply @ AED {totalPrice}*</button>
            <div className='requirements'>
            <div className='requirements-points'>
            <p className='requirement-header'>What are the requirements for UAE Visa?</p>
            <li>A copy of guarantor's passport's first, last and visa pages</li>
            <li>A copy of each visitor's passport's first and last pages</li>
            <li>Each visitor's passport-size photograph, taken against a white background</li>
            <li>Each visitor's passport should have a minimum validity of 6 months</li>
            <br></br>
            <p className='requirement-header'>*Things to remember:</p>
            <li>Usually it takes 2-3 days to process a visa.</li>
            <li>Express service(within 24 hrs) available at additional AED 100 cost per visa.</li>
            <li>No refund, if visa is rejected by the government for any reason.</li>
            <li>Cancellation or refund not allowed for any visas, once processed.</li>
            <li>More discounted rates available on group bookings.</li>
            </div>
            </div>

            <ChildModal onClose={onClose} date={currentDate} price={totalPrice} count={count} data={data} show={show}/>
        </div>
     
      
        </div>
    )
}

export default Visa;