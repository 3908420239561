import { Container, Row } from "react-bootstrap";
import logo from "../assets/img/skyline.png";
import { Contact } from "./Contact";

export const Footer = () => {
  return (
    <footer className="footer"> 
      <Container>
        <Row className="align-items-center">
          <Contact />
          <p className="copyright">Copyright 2023 Trip Away. All Rights Reserved</p>
          <img className="skyline" src={logo} alt="Logo" />
        </Row>
      </Container>
    </footer>
  )
}
